import "../../assets/css/tabs/informationCompliance.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"
import supplierCommodities from "../../assets/jsonData/supplierCommodities.json"

import commentBlank from "../../assets/images/commentBlank.svg"
import commentFill from "../../assets/images/commentFill.svg"
import close from "../../assets/images/close.svg"
import deleteIcon from "../../assets/images/delete.svg"

import React, { useEffect, useRef, useState } from "react"
import { Dropdown } from "react-bootstrap";
import { useCookies } from "react-cookie"
import axios from "axios"
import { formatCommas } from "../../utilities/formatCommas"
import Swal from "sweetalert2"

const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY

export default function InformationCompliance(props) {
	// VARIABLE TO MANAGE USER LOGGED IN
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	// CATEGORIES INFORMATION
	const [industrySupplier, setIndustrySupplier] = useState(null)

	const [generalCommentMenu, setGeneralCommentMenu] = useState(false);
	const [facilitiesCommentMenu, setFacilitiesCommentMenu] = useState(false);
	const [salesCommentMenu, setSalesCommentMenu] = useState(false);

	const generalCommentMenuRef = useRef(null);
	const facilitiesCommentMenuRef = useRef(null);
	const salesCommentMenuRef = useRef(null);

	// REFERENCE FOR THE COMMODITIES MENU
	const commodityRef = useRef(null)


	useEffect(() => {
		getAllSupplierIndustries()

		document.body.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.body.removeEventListener("click", closeMenus)
		}
	}, [])

	const closeMenus = (e) => {
		if (!generalCommentMenuRef.current.contains(e.target)) {
			setGeneralCommentMenu(false)
		}

		if (!facilitiesCommentMenuRef.current.contains(e.target)) {
			setFacilitiesCommentMenu(false)
		}

		if (!salesCommentMenuRef.current.contains(e.target)) {
			setSalesCommentMenu(false)
		}

		if (commodityRef.current && commodityRef.current.contains(e.target)) {
			commodityRef.current.classList.toggle("active")
			commodityRef.current.nextElementSibling.classList.toggle("active")
		} else {
			commodityRef.current.classList.remove("active")
			commodityRef.current.nextElementSibling.classList.remove("active")
		}
	}

	const addCategory = (target) => {
		const categoriesCopy = props.informationCompliance.facilitiesCapabilities.categories
		if (categoriesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			categoriesCopy.splice(categoriesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			categoriesCopy.push(target.value.toUpperCase())
		}

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					facilitiesCapabilities: {
						...prevState.informationCompliance.facilitiesCapabilities,
						category: categoriesCopy
					}
				}
			}
		})
	}

	// FUNCTION TO ADD OR REMOVE CATEGORIES TO THE SUPPLIER/
	const addCommodity = (target) => {
		const commoditiesCopy = props.informationCompliance.facilitiesCapabilities.commodities
		if (commoditiesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			commoditiesCopy.splice(commoditiesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			commoditiesCopy.push(target.value.toUpperCase())
		}

		if (commoditiesCopy.includes("NOT APPLICABLE")) {
			props.setSupplier(prevState => {
				return {
					...prevState,
					informationCompliance: {
						...prevState.informationCompliance,
						facilitiesCapabilities: {
							...prevState.informationCompliance.facilitiesCapabilities,
							commodities: ["NOT APPLICABLE"]
						}
					}
				}
			})
		} else {
			props.setSupplier(prevState => {
				return {
					...prevState,
					informationCompliance: {
						...prevState.informationCompliance,
						facilitiesCapabilities: {
							...prevState.informationCompliance.facilitiesCapabilities,
							commodities: commoditiesCopy
						}
					}
				}
			})
		}
	}

	// FUNCTION TO GET ALL THE SUPPLIER INDUSTRIES
	const getAllSupplierIndustries = () => {
		axios.get(URI_INDUSTRY).then(response => {
			setIndustrySupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener industrias del servidor" + err)
		})
	}

	// FUNCTION TO ADD CUSTOMERS
	const addCustomers = () => {
		const customers = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customers))

		customers.push({
			name: "",
			industry: "",
			percentSales: 0,
			yearsService: props.serverDate.year
		})

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					salesInformation: {
						...prevState.informationCompliance.salesInformation,
						customers: customers
					}
				}
			}
		})
	}

	// FUNCTION TO DELETE CUSTOMERS
	const deleteCustomer = (position) => {
		const customers = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customers))

		customers.splice(position, 1)

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					salesInformation: {
						...prevState.informationCompliance.salesInformation,
						customers: customers
					}
				}
			}
		})
	}

	// FUNCTION TO HANDLE WHEN A CUSTOMER CHANGES
	const handleCustomerChange = (positions, key, value) => {
		const customers = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customers))

		customers[positions][key] = value

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					salesInformation: {
						...prevState.informationCompliance.salesInformation,
						customers: customers
					}
				}
			}
		})
	}

	// FUNCTION TO ADD CUSTOMERS CERTIFIED
	const addCustomersCertified = () => {
		const customersCertified = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customersCertified))

		customersCertified.push("")

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					salesInformation: {
						...prevState.informationCompliance.salesInformation,
						customersCertified: customersCertified
					}
				}
			}
		})
	}

	// FUNCTION TO DELETE CUSTOMERS CERTIFIED
	const deleteCustomerCertified = (position) => {
		const customersCertified = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customersCertified))

		customersCertified.splice(position, 1)

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					salesInformation: {
						...prevState.informationCompliance.salesInformation,
						customersCertified: customersCertified
					}
				}
			}
		})
	}

	// FUNCTION TO ADD AGREEMENTS
	const addAgreements = () => {
		const agreements = props.informationCompliance.generalInformation.cvp.agreements

		agreements.push({
			document: null,
			uploadedAt: null,
			yearCreatedAt: null,
			monthCreatedAt: null,
			dayCreatedAt: null,
			percentCommision: 1,
			typeCommision: "",
		})

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					generalInformation: {
						...prevState.informationCompliance.generalInformation,
						cvp: {
							...prevState.informationCompliance.generalInformation.cvp,
							agreements: agreements
						}
					}
				}
			}
		})
	}

	// FUNCTION TO DELETE CUSTOMERS CERTIFIED
	const deleteAgreement = (position) => {
		const agreements = props.informationCompliance.generalInformation.cvp.agreements

		agreements.splice(position, 1)

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					generalInformation: {
						...prevState.informationCompliance.generalInformation,
						cvp: {
							...prevState.informationCompliance.generalInformation.cvp,
							agreements: agreements
						}
					}
				}
			}
		})
	}

	// FUNCTION TO HANDLE AGREEMENT FILE CHANGES
	const handleAgreementFileChange = (position, value) => {
		if (value || value === "") {
			if (value.type === "application/pdf" || value === "") {
				const agreements = props.informationCompliance.generalInformation.cvp.agreements

				agreements[position].document = value

				const supplierToUpdate = {
					...props.supplier,
					informationCompliance: {
						...props.supplier.informationCompliance,
						generalInformation: {
							...props.supplier.informationCompliance.generalInformation,
							cvp: {
								...props.supplier.informationCompliance.generalInformation.cvp,
								agreements: agreements
							}
						}
					}
				}

				props.handleSaveChanges(supplierToUpdate)
			} else {
				value.value = null
				Swal.fire({
					icon: 'error',
					text: 'The file is not a PDF.',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}
		}
	}

	// FUNCTION TO HANDLE AGREEMENT VALUE CHANGES
	const handleAgreementChange = (position, property, value) => {
		const agreements = props.informationCompliance.generalInformation.cvp.agreements

		if (property === "percentCommision") {
			if (value > 0 && value < 101) {
				agreements[position][property] = value
			}
		} else {
			agreements[position][property] = value
		}

		props.setSupplier(prevState => {
			return {
				...prevState,
				informationCompliance: {
					...prevState.informationCompliance,
					generalInformation: {
						...prevState.informationCompliance.generalInformation,
						cvp: {
							...prevState.informationCompliance.generalInformation.cvp,
							agreements: agreements
						}
					}
				}
			}
		})
	}

	return (
		<section className="informationCompliance">
			<div className="inputSections">
				<div className="titleAndActions">
					<h5 className="title">
						General Information
					</h5>
					<img ref={generalCommentMenuRef} className="comment" src={props.informationCompliance.generalInformation.generalInformationComment ? commentFill : commentBlank} alt="Prodensa Supplier" onClick={() => setGeneralCommentMenu(!generalCommentMenu)} />
					<div className={`commentContainer ${generalCommentMenu ? "showMenu" : ""}`}>
						<img className={`${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`} src={close} alt="Prodensa Supplier" />
						<h5>Comments</h5>
						<textarea
							value={props.informationCompliance.generalInformation.generalInformationComment}
							rows={5}
							onChange={(e) => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											generalInformation: {
												...prevState.informationCompliance.generalInformation,
												generalInformationComment: e.target.value
											}
										}
									}
								})
							}}
							placeholder="Write comment..."
							disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
							onClick={(e) => e.stopPropagation()}
						/>
						<div className={`actions ${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`}>
							<button className="btnBgBlueTextGradient" onClick={() => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											generalInformation: {
												...prevState.informationCompliance.generalInformation,
												generalInformationComment: ""
											}
										}
									}
								})
							}}>Cancel</button>
							<button className="btnBgBlueTextGradient">Apply comment</button>
						</div>
					</div>
				</div>

				<div className="inputContainer">
					<span className="label">Company country</span>
					<input className="disabled" value={countryInformation.find(countries => countries.code === props.informationCompliance.generalInformation.country).country} type="text" disabled />
				</div>
				<div className="inputContainer">
					<span className="label">
						{countryInformation.find(countries => countries.code === props.informationCompliance.generalInformation.country).typePersonLabel}
					</span>
					<input className="disabled" value={props.informationCompliance.generalInformation.typePerson} type="text" disabled />
				</div>
				<div className="inputContainer">
					<span className="label">Username</span>
					<input className="disabled" value={props.username} type="text" disabled />
				</div>
				<div className="inputContainer">
					<span className="label">Company name</span>
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.companyName} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											companyName: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">
						{countryInformation.find(countries => countries.code === props.informationCompliance.generalInformation.country).rfcLabel}
					</span>
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.rfc} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											rfc: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Year Founded</span>
					<input value={props.informationCompliance.generalInformation.yearFounded} type="number" placeholder="Year founded"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											yearFounded: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Number of employees</span>
					<input value={props.informationCompliance.generalInformation.numberEmployee} type="text" placeholder="Number of employees"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											numberEmployee: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Phone</span>
					<input value={props.informationCompliance.generalInformation.companyPhone} type="text" placeholder="Company phone"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											companyPhone: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Division <bg-grey>(If applicable)</bg-grey></span>
					<input value={props.informationCompliance.generalInformation.division} type="text" placeholder="Division"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											division: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer radio">
					<span className="label">Do you have a REPSE number?</span>
					<input id="hasRepseYes" checked={props.informationCompliance.generalInformation.hasRepse ? true : false} type="radio" name="repse"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											hasRepse: true
										}
									}
								}
							})
						}} />
					<label htmlFor="hasRepseYes">Yes</label>
					<input id="hasRepseNo" className="no" checked={props.informationCompliance.generalInformation.hasRepse ? false : true} type="radio" name="repse"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											hasRepse: false,
											numberRepse: ""
										}
									}
								}
							})
						}} />
					<label htmlFor="hasRepseNo">No</label>
				</div>
				<div className="inputContainer">
					<span className="label">REPSE Number</span>
					<input
						className={!props.informationCompliance.generalInformation.hasRepse ? "disabled" : undefined}
						disabled={!props.informationCompliance.generalInformation.hasRepse}
						value={props.informationCompliance.generalInformation.numberRepse} type="text" placeholder="REPSE Number"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											numberRepse: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Notification contacts <bg-grey>(Separate by comma)</bg-grey></span>
					<input value={props.informationCompliance.generalInformation.notificationContacts} type="text" placeholder="example1@example.com, example2@example.com"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											notificationContacts: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Website / Social Network</span>
					<input value={props.informationCompliance.generalInformation.website} type="text" placeholder="Website/Social Network"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											website: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Presentation/Curriculum</span>
					<div className="fileContainer">
						{props.informationCompliance.generalInformation.curriculum.document ?
							<>
								<a className="downloadFile btnBgBlueTextGradient"
									href={process.env.REACT_APP_URI_UPLOADS +
										props.informationCompliance.generalInformation.rfc + "/" +
										(props.informationCompliance.generalInformation.curriculum.document.name ?? props.informationCompliance.generalInformation.curriculum.document)}
									target="_blank">View attached file</a>
								<span className="fileName">{props.informationCompliance.generalInformation.curriculum.document.name ?? props.informationCompliance.generalInformation.curriculum.document}</span>
								<img className="close" src={close} alt="Prodensa Supplier"
									onClick={() => {
										props.setSupplier(prevState => {
											return {
												...prevState,
												informationCompliance: {
													...prevState.informationCompliance,
													generalInformation: {
														...prevState.informationCompliance.generalInformation,
														curriculum: {
															...prevState.informationCompliance.generalInformation.curriculum,
															document: ""
														}
													}
												}
											}
										})
									}} />
							</>
							: <input id="file" accept=".pdf" type="file"
								onChange={(e) => {
									if (e.target.files[0]) {
										if (e.target.files[0].type === "application/pdf") {
											props.setSupplier(prevState => {
												return {
													...prevState,
													informationCompliance: {
														...prevState.informationCompliance,
														generalInformation: {
															...prevState.informationCompliance.generalInformation,
															curriculum: {
																...prevState.informationCompliance.generalInformation.curriculum,
																document: e.target.files[0]
															}
														}
													}
												}
											})
										} else {
											e.target.value = null
											Swal.fire({
												icon: 'error',
												text: 'The file is not a PDF.',
												showConfirmButton: false,
												timer: 5000,
												timerProgressBar: true,
											})
										}
									}
								}} />
						}
					</div>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer">
					<span className="label">Official address</span>
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.address.streetAndNumber} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											address: {
												...prevState.informationCompliance.generalInformation.address,
												streetAndNumber: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.address.zipCode} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											address: {
												...prevState.informationCompliance.generalInformation.address,
												zipCode: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.address.city} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											address: {
												...prevState.informationCompliance.generalInformation.address,
												city: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<Dropdown>
						<Dropdown.Toggle className={`${props.informationCompliance.generalInformation.address.state ? "" : "default"} ${userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined}`}>
							{props.informationCompliance.generalInformation.address.state ? props.informationCompliance.generalInformation.address.state : "State"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{countryInformation.find(countries => countries.code === props.informationCompliance.generalInformation.country).states.map((state, index) => (
								<Dropdown.Item key={index}
									onClick={(e) => {
										props.setSupplier(prevState => {
											return {
												...prevState,
												informationCompliance: {
													...prevState.informationCompliance,
													generalInformation: {
														...prevState.informationCompliance.generalInformation,
														address: {
															...prevState.informationCompliance.generalInformation.address,
															state: e.target.innerText
														}
													}
												}
											}
										})
									}} >
									{state.name}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer">
					<span className="label">Contact information</span>
					<input value={props.informationCompliance.generalInformation.contactInformation?.name} type="text" placeholder="Name"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											contactInformation: {
												...prevState.informationCompliance.generalInformation.contactInformation,
												name: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.generalInformation.contactInformation?.role} type="text" placeholder="Role"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											contactInformation: {
												...prevState.informationCompliance.generalInformation.contactInformation,
												role: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} value={props.informationCompliance.generalInformation.contactInformation?.email} type="text" disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											contactInformation: {
												...prevState.informationCompliance.generalInformation.contactInformation,
												email: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.generalInformation.contactInformation?.phone} type="text" placeholder="Contact phone"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											contactInformation: {
												...prevState.informationCompliance.generalInformation.contactInformation,
												phone: e.target.value
											}
										}
									}
								}
							})
						}}
					/>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer">
					<span className="label">Legal representative information</span>
					<input value={props.informationCompliance.generalInformation.legalRepresentativeInformation?.name} type="text" placeholder="Name"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											legalRepresentativeInformation: {
												...prevState.informationCompliance.generalInformation.legalRepresentativeInformation,
												name: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.generalInformation.legalRepresentativeInformation?.email} type="text" placeholder="Email"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											legalRepresentativeInformation: {
												...prevState.informationCompliance.generalInformation.legalRepresentativeInformation,
												email: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.generalInformation.legalRepresentativeInformation?.phone} type="text" placeholder="Phone"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											legalRepresentativeInformation: {
												...prevState.informationCompliance.generalInformation.legalRepresentativeInformation,
												phone: e.target.value
											}
										}
									}
								}
							})
						}}
					/>
				</div>
				{userLogged && (userLogged.rol !== "Admin client" || userLogged.rol !== "client") && <div className="inputContainer radio">
					<span className="label">Certified Vendor Program</span>
					<input id="cvpYes" checked={props.informationCompliance.generalInformation.cvp.certified ? true : false} type="radio" name="cvp"
						disabled={userLogged && (userLogged.rol !== "Master" && userLogged.rol !== "Super admin") ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											cvp: {
												...prevState.informationCompliance.generalInformation.cvp,
												certified: true
											}
										}
									}
								}
							})
						}} />
					<label htmlFor="cvpYes">Yes</label>
					<input id="cvpNo" className="no" checked={props.informationCompliance.generalInformation.cvp.certified ? false : true} type="radio" name="cvp"
						disabled={userLogged && (userLogged.rol !== "Master" && userLogged.rol !== "Super admin") ? true : false}
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										generalInformation: {
											...prevState.informationCompliance.generalInformation,
											cvp: {
												...prevState.informationCompliance.generalInformation.cvp,
												certified: false
											}
										}
									}
								}
							})
						}} />
					<label htmlFor="cvpNo">No</label>
				</div>}

				<div className="w-100"></div>

				{(userLogged && (userLogged.rol !== "Admin client" || userLogged.rol !== "client") && props.informationCompliance.generalInformation.cvp.certified) &&
					<React.Fragment>
						<div className="inputContainer title mb-0">
							<span className="label">Agreement</span>
						</div>
						<div className="inputContainer title mb-0">
							<span className="label">Commision percent</span>
						</div>
						<div className="inputContainer title mb-0">
							<span className="label">Commision type</span>
						</div>
						{props.informationCompliance.generalInformation.cvp.agreements.map((agreement, index) => (
							<div key={index} className="agreementInfo">
								<div className="inputContainer">
									<div className="fileContainer">
										{userLogged.rol === "Master" || userLogged.rol === "Super admin"
											? agreement.document
												? <>
													<a className="downloadFile btnBgBlueTextGradient"
														href={process.env.REACT_APP_URI_UPLOADS +
															props.informationCompliance.generalInformation.rfc + "/" +
															(agreement.document.name ?? agreement.document)}
														target="_blank">View attached file</a>
													<span className="fileName">{agreement.document.name ?? agreement.document}</span>
													<img className="close" src={close} alt="Prodensa Supplier"
														onClick={() => handleAgreementFileChange(index, "")} />
												</>
												: <input id="file" accept=".pdf" type="file"
													onChange={(e) => handleAgreementFileChange(index, e.target.files[0])} />
											: agreement.document
												? <>
													<a className="downloadFile btnBgBlueTextGradient"
														href={process.env.REACT_APP_URI_UPLOADS +
															props.informationCompliance.generalInformation.rfc + "/" +
															(agreement.document.name ?? agreement.document)}
														target="_blank">View attached file</a>
													<span className="fileName">{agreement.document.name ?? agreement.document}</span>
													{userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin") && <img className="close" src={close} alt="Prodensa Supplier"
														onClick={() => handleAgreementFileChange(index, "")} />}
												</>
												: <span className="fileName">No file uploaded</span>
										}
									</div>
								</div>

								<div className="inputContainer percent">
									<input type="number" value={agreement.percentCommision}
										className={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined}
										disabled={userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined}
										onChange={(e) => handleAgreementChange(index, "percentCommision", +e.target.value)}
									/>
								</div>

								<div className="inputContainer">
									<Dropdown>
										<Dropdown.Toggle className={`${agreement.typeCommision ? "" : "default"} ${userLogged && userLogged.rol === "Supplier" ? "disabled" : undefined} `}>
											{agreement.typeCommision ? agreement.typeCommision : "Type of commision"}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item onClick={(e) => handleAgreementChange(index, "typeCommision", e.target.innerText)} >Margin</Dropdown.Item>
											<Dropdown.Item onClick={(e) => handleAgreementChange(index, "typeCommision", e.target.innerText)} >Sale</Dropdown.Item>
											<Dropdown.Item onClick={(e) => handleAgreementChange(index, "typeCommision", e.target.innerText)} >Volume</Dropdown.Item>
											<Dropdown.Item onClick={(e) => handleAgreementChange(index, "typeCommision", e.target.innerText)} >Shipments</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>

								{userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin") && <img className="deleteIcon" src={deleteIcon} onClick={() => deleteAgreement(index)} alt="Prodensa Supplier" />}
							</div>
						))}
						<div className="w-100"></div>
						{userLogged && (userLogged.rol === "Master" || userLogged.rol === "Super admin") && <button className="btnBgBlueTextGradient add" onClick={() => addAgreements()}>Add Agreement</button>}
					</React.Fragment>
				}
			</div>

			<div className="inputSections">
				<div className="titleAndActions">
					<h5 className="title">
						Facilities & Capabilities
					</h5>
					<img ref={facilitiesCommentMenuRef} className="comment" src={props.informationCompliance.facilitiesCapabilities.facilitiesCapabilitiesComment ? commentFill : commentBlank} alt="Prodensa Supplier" onClick={() => setFacilitiesCommentMenu(!facilitiesCommentMenu)} />
					<div className={`commentContainer ${facilitiesCommentMenu ? "showMenu" : ""}`}>
						<img className={`${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`} src={close} alt="Prodensa Supplier" />
						<h5>Comments</h5>
						<textarea
							value={props.informationCompliance.facilitiesCapabilities.facilitiesCapabilitiesComment}
							rows={5}
							onChange={(e) => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											facilitiesCapabilities: {
												...prevState.informationCompliance.facilitiesCapabilities,
												facilitiesCapabilitiesComment: e.target.value
											}
										}
									}
								})
							}}
							placeholder="Write comment..."
							disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
							onClick={(e) => e.stopPropagation()}
						/>
						<div className={`actions ${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`}>
							<button className="btnBgBlueTextGradient" onClick={() => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											facilitiesCapabilities: {
												...prevState.informationCompliance.facilitiesCapabilities,
												facilitiesCapabilitiesComment: ""
											}
										}
									}
								})
							}}>Cancel</button>
							<button className="btnBgBlueTextGradient">Apply comment</button>
						</div>
					</div>
				</div>

				<div className="inputContainer moreWidth">
					<span className="label">Manufactured products <bg-grey>(Core Business)</bg-grey></span>
					<input type="text" value={props.informationCompliance.facilitiesCapabilities.manufacturedProducts ?? ""} placeholder="Manufactured products"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											manufacturedProducts: e.target.value
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Industry</span>
					<Dropdown>
						<Dropdown.Toggle className={props.informationCompliance.facilitiesCapabilities.categories.length > 0 ? "" : "default"}>
							{props.informationCompliance.facilitiesCapabilities.categories.length > 0 ? props.informationCompliance.facilitiesCapabilities.categories.map(category => category + ", ") : "Industry"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id={`categoriesOther`} type="checkbox"
									defaultChecked={props.informationCompliance.facilitiesCapabilities.categories.some(category => category === "OTHER") ? true : false}
									value={"Other"} onClick={(e) => addCategory(e.target)} />
								<label htmlFor={`categoriesOther`}>Other</label>
							</div>
							{industrySupplier && industrySupplier.map((industry, index) => {
								if (industry.name !== "Global") {
									return (
										<div key={index} className="optionCategory">
											<input id={`categories${index}`} type="checkbox" defaultChecked={props.informationCompliance.facilitiesCapabilities.categories.some(category => category === industry.name.toUpperCase()) ? true : false} value={industry.name} onClick={(e) => addCategory(e.target)} />
											<label htmlFor={`categories${index}`}>{industry.name}</label>
										</div>
									)
								}
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className={`inputContainer otherOptions ${props.informationCompliance.facilitiesCapabilities.categories.includes("OTHER") ? "show" : ""}`}>
					<span className="label">Other categories</span>
					<input value={props.informationCompliance.facilitiesCapabilities.otherCategories}
						placeholder="It systems, Automation, Cleaning Services" type="text"
						onChange={(e) => props.setSupplier(prevState => {
							return {
								...prevState,
								informationCompliance: {
									...prevState.informationCompliance,
									facilitiesCapabilities: {
										...prevState.informationCompliance.facilitiesCapabilities,
										otherCategories: e.target.value
									}
								}
							}
						})}
					/>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer commodityContainer">
					<span className="label">Commodities of supplier</span>
					<div ref={commodityRef} className={`buttonCommodities ${props.informationCompliance.facilitiesCapabilities.commodities.length < 1 ? "default" : ""}`}>
						{props.informationCompliance.facilitiesCapabilities.commodities.length > 0 ? props.informationCompliance.facilitiesCapabilities.commodities.map((commodity, index) => {
							return props.informationCompliance.facilitiesCapabilities.commodities.length > index + 1 ? commodity + ", " : commodity
						}) : "Commodities"}
					</div>
					<div className="commodityList" onClick={(e) => e.stopPropagation()}>
						<div className="optionCommodity">
							<input id={`commodityN/A`} value={"Not applicable"} type="checkbox" onChange={(e) => addCommodity(e.target)}
								checked={props.informationCompliance.facilitiesCapabilities.commodities.includes("NOT APPLICABLE")} />
							<label htmlFor={`commodityN/A`}>Not applicable</label>
						</div>
						<div className={`optionCommodity ${props.informationCompliance.facilitiesCapabilities.commodities.includes("NOT APPLICABLE") ? "disabled" : ""}`}>
							<input id={`commodityOther`} value={"Other"} type="checkbox" onChange={(e) => addCommodity(e.target)}
								checked={props.informationCompliance.facilitiesCapabilities.commodities.includes("OTHER")} />
							<label htmlFor={`commodityOther`}>Other</label>
						</div>
						{supplierCommodities && supplierCommodities.map((commodity, index) => (
							<React.Fragment key={index}>
								<h5 className="commodityName">{commodity.commodityName}</h5>
								{commodity.commodityProcess.map((process, processIndex) => (
									<div key={`${index}${processIndex}`} className={`optionCommodity ${props.informationCompliance.facilitiesCapabilities.commodities.includes("NOT APPLICABLE") ? "disabled" : ""}`}>
										<input id={`commodity${index}${processIndex}`} value={process} type="checkbox" onChange={(e) => addCommodity(e.target)}
											checked={props.informationCompliance.facilitiesCapabilities.commodities.includes(process.toUpperCase())} />
										<label htmlFor={`commodity${index}${processIndex}`}>{process}</label>
									</div>
								))}
							</React.Fragment>
						))}
					</div>
				</div>
				<div className={`inputContainer otherOptions ${props.informationCompliance.facilitiesCapabilities.commodities.includes("OTHER") ? "show" : ""}`}>
					<span className="label">Other commodities</span>
					<input value={props.informationCompliance.facilitiesCapabilities.otherCommodities}
						placeholder="Display, Overlays, Laser Cutting" type="text"
						onChange={(e) => props.setSupplier(prevState => {
							return {
								...prevState,
								informationCompliance: {
									...prevState.informationCompliance,
									facilitiesCapabilities: {
										...prevState.informationCompliance.facilitiesCapabilities,
										otherCommodities: e.target.value
									}
								}
							}
						})}
					/>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer area">
					<span className="label">Land area</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.landArea ?? "").formattedValue} placeholder="1.000"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											landArea: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer radio">
					<span className="label"></span>
					<input id="ownedYes" checked={props.informationCompliance.facilitiesCapabilities.owned ?? false} type="radio" name="property"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											owned: true,
											rented: false
										}
									}
								}
							})
						}} />
					<label htmlFor="ownedYes">Owned</label>
					<input id="ownedNo" checked={props.informationCompliance.facilitiesCapabilities.rented ?? false} type="radio" name="property"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											owned: false,
											rented: true
										}
									}
								}
							})
						}} />
					<label htmlFor="ownedNo">Rented</label>
				</div>
				<div className="inputContainer area">
					<span className="label">Plant area</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.plantArea ?? "").formattedValue} placeholder="1,000"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											plantArea: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="w-100"></div>
				<div className="inputContainer">
					<span className="label">Current working shifts</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.currentWorkingShift ?? "").formattedValue} placeholder="40"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											currentWorkingShift: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer percent">
					<span className="label">Installed capacity <bg-grey>(%)</bg-grey></span>
					<input type="number" value={props.informationCompliance.facilitiesCapabilities.percentCapacity ?? 1} placeholder="80"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											percentCapacity: e.target.value > 0 && e.target.value <= 100 ? e.target.value : prevState.informationCompliance.facilitiesCapabilities.percentCapacity
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Total workers</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.totalWorkers ?? "").formattedValue} placeholder="130"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											totalWorkers: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Floor workers</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.floorWorkers ?? "").formattedValue} placeholder="30"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											floorWorkers: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Office workers</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.officeWorkers ?? "").formattedValue} placeholder="100"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											officeWorkers: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Quality employees</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.qualityEmployees ?? "").formattedValue} placeholder="15"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											qualityEmployees: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<span className="label">Engineering employees</span>
					<input type="text" value={formatCommas(props.informationCompliance.facilitiesCapabilities.engineerEmployees ?? "").formattedValue} placeholder="20"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											engineerEmployees: formatCommas(e.target.value).numericValue
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer radio">
					<span className="label">Quality assurance manager</span>
					<input id="qualityYes" type="radio" checked={props.informationCompliance.facilitiesCapabilities.qualityManager ? true : false} name="quality"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											qualityManager: true
										}
									}
								}
							})
						}} />
					<label htmlFor="qualityYes">Yes</label>
					<input id="qualityNo" className="no" type="radio" checked={props.informationCompliance.facilitiesCapabilities.qualityManager ? false : true} name="quality"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											qualityManager: false
										}
									}
								}
							})
						}} />
					<label htmlFor="qualityNo">No</label>
				</div>
				<div className="inputContainer radio ms-auto">
					<span className="label">Engineering manager</span>
					<input id="managerYes" type="radio" checked={props.informationCompliance.facilitiesCapabilities.engineerManager ? true : false} name="manager"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											engineerManager: true
										}
									}
								}
							})
						}} />
					<label htmlFor="managerYes">Yes</label>
					<input id="managerNo" className="no" type="radio" checked={props.informationCompliance.facilitiesCapabilities.engineerManager ? false : true} name="manager"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											engineerManager: false
										}
									}
								}
							})
						}} />
					<label htmlFor="managerNo">No</label>
				</div>
				<div className="inputContainer moreWidth">
					<span className="label">Description of products/services offered by company</span>
					<textarea value={props.informationCompliance.facilitiesCapabilities.description} rows="5"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										facilitiesCapabilities: {
											...prevState.informationCompliance.facilitiesCapabilities,
											description: e.target.value
										}
									}
								}
							})
						}} />
				</div>
			</div>

			<div className="inputSections">
				<div className="titleAndActions">
					<h5 className="title">
						Sales Information
					</h5>
					<img ref={salesCommentMenuRef} className="comment" src={props.informationCompliance.salesInformation.salesInformationComment ? commentFill : commentBlank} alt="Prodensa Supplier" onClick={() => setSalesCommentMenu(!salesCommentMenu)} />
					<div className={`commentContainer ${salesCommentMenu ? "showMenu" : ""}`}>
						<img className={`${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`} src={close} alt="Prodensa Supplier" />
						<h5>Comments</h5>
						<textarea
							value={props.informationCompliance.salesInformation.salesInformationComment}
							rows={5}
							onChange={(e) => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											salesInformation: {
												...prevState.informationCompliance.salesInformation,
												salesInformationComment: e.target.value
											}
										}
									}
								})
							}}
							placeholder="Write comment..."
							disabled={userLogged && userLogged.rol === "Supplier" ? true : false}
							onClick={(e) => e.stopPropagation()}
						/>
						<div className={`actions ${userLogged && userLogged.rol === "Supplier" ? "d-none" : ""}`}>
							<button className="btnBgBlueTextGradient" onClick={() => {
								props.setSupplier(prevState => {
									return {
										...prevState,
										informationCompliance: {
											...prevState.informationCompliance,
											salesInformation: {
												...prevState.informationCompliance.salesInformation,
												salesInformationComment: ""
											}
										}
									}
								})
							}}>Cancel</button>
							<button className="btnBgBlueTextGradient">Apply comment</button>
						</div>
					</div>
				</div>

				<div className="inputContainer">
					<span className="label">Contact information</span>
					<input value={props.informationCompliance.salesInformation.contactInformation?.name} type="text" placeholder="Name"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											contactInformation: {
												...prevState.informationCompliance.salesInformation.contactInformation,
												name: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.salesInformation.contactInformation?.role} type="text" placeholder="Role"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											contactInformation: {
												...prevState.informationCompliance.salesInformation.contactInformation,
												role: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.salesInformation.contactInformation?.email} type="text" placeholder="Email address"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											contactInformation: {
												...prevState.informationCompliance.salesInformation.contactInformation,
												email: e.target.value
											}
										}
									}
								}
							})
						}} />
				</div>
				<div className="inputContainer">
					<input value={props.informationCompliance.salesInformation.contactInformation?.phone} type="text" placeholder="Contact phone"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											contactInformation: {
												...prevState.informationCompliance.salesInformation.contactInformation,
												phone: e.target.value
											}
										}
									}
								}
							})
						}}
					/>
				</div>

				<div className="w-100"></div>

				<div className="inputContainer dollars">
					<span className="label">Annual sales {props.serverDate.year} <bg-grey>(Estimated)</bg-grey></span>
					<input value={formatCommas(props.informationCompliance.salesInformation.anualSales.find(sale => sale.yearSale === props.serverDate.year)?.sales ?? "").formattedValue} type="text"
						onChange={(e) => {
							const anualSales = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.anualSales))

							const currentYearSale = anualSales.find(sale => sale.yearSale === props.serverDate.year);
							const formattedSales = formatCommas(e.target.value ? e.target.value : 0).numericValue;
							if (currentYearSale) { currentYearSale.sales = formattedSales; }
							else
							{ anualSales.push({ yearSale: props.serverDate.year, sales: formattedSales }); }

							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											anualSales: anualSales
										}
									}
								}
							})
						}}
					/>
				</div>
				<div className="inputContainer dollars">
					<span className="label">Annual sales {props.serverDate.year - 1}</span>
					<input value={formatCommas(props.informationCompliance.salesInformation.anualSales.find(sale => sale.yearSale === props.serverDate.year - 1)?.sales ?? "").formattedValue} type="text"
						onChange={(e) => {
							const anualSales = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.anualSales))

							const currentYearSale = anualSales.find(sale => sale.yearSale === props.serverDate.year);
							const formattedSales = formatCommas(e.target.value ? e.target.value : 0).numericValue;
							if (currentYearSale) { currentYearSale.sales = formattedSales; }
							else
							{ anualSales.push({ yearSale: props.serverDate.year, sales: formattedSales }); }

							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											anualSales: anualSales
										}
									}
								}
							})
						}}
					/>
				</div>
				<div className="inputContainer dollars">
					<span className="label">Annual sales {props.serverDate.year - 2}</span>
					<input value={formatCommas(props.informationCompliance.salesInformation.anualSales.find(sale => sale.yearSale === props.serverDate.year - 2)?.sales ?? "").formattedValue} type="text"
						onChange={(e) => {
							const anualSales = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.anualSales))
							
							const currentYearSale = anualSales.find(sale => sale.yearSale === props.serverDate.year);
							const formattedSales = formatCommas(e.target.value ? e.target.value : 0).numericValue;
							if (currentYearSale) { currentYearSale.sales = formattedSales; }
							else
							{ anualSales.push({ yearSale: props.serverDate.year, sales: formattedSales }); }

							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											anualSales: anualSales
										}
									}
								}
							})
						}}
					/>
				</div>

				<span className="labelSales">Sales distribution among top customers</span>
				<div className="inputContainer fourItems title">
					<span className="label">Customer name</span>
				</div>
				<div className="inputContainer fourItems title">
					<span className="label">Industry</span>
				</div>
				<div className="inputContainer fourItems title">
					<span className="label">% of annual sales</span>
				</div>
				<div className="inputContainer fourItems title">
					<span className="label">Years in service</span>
				</div>
				{props.informationCompliance.salesInformation.customers.map((customer, index) => (
					<div key={index} className="customerInfo">
						<div className="inputContainer fourItems">
							<input type="text" value={customer.name} onChange={(e) => handleCustomerChange(index, "name", e.target.value)} />
						</div>
						<div className="inputContainer fourItems">
							<input type="text" value={customer.industry} onChange={(e) => handleCustomerChange(index, "industry", e.target.value)} />
						</div>
						<div className="inputContainer fourItems">
							<input type="number" value={customer.percentSales} onChange={(e) => handleCustomerChange(index, "percentSales", e.target.value)} />
						</div>
						<div className="inputContainer fourItems">
							<input type="number" value={customer.yearsService} onChange={(e) => handleCustomerChange(index, "yearsService", e.target.value)} />
						</div>
						<img className="deleteIcon" src={deleteIcon} onClick={() => deleteCustomer(index)} alt="Prodensa Supplier" />
					</div>
				))}
				<button className="btnBgBlueTextGradient add" onClick={() => addCustomers()}>Add Customer</button>

				<div className="w-100 mt-5"></div>

				<div className="inputContainer radio">
					<span className="label">Currently exporting to the US?</span>
					<input id="exportUsYes" type="radio" checked={props.informationCompliance.salesInformation.exportUS ? true : false} name="exportUs"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											exportUS: true
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="exportUsYes">Yes</label>
					<input id="exportUsNo" className="no" type="radio" checked={props.informationCompliance.salesInformation.exportUS ? false : true} name="exportUs"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											exportUS: false
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="exportUsNo">No</label>
				</div>
				<div className="inputContainer radio">
					<span className="label">Export to any other country? <bg-grey>(Please specify)</bg-grey></span>
					<input id="exportOtherYes" type="radio" checked={props.informationCompliance.salesInformation.exportOther ? true : false} name="exportOther"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											exportOther: true
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="exportOtherYes">Yes</label>
					<input id="exportOtherNo" className="no" type="radio" checked={props.informationCompliance.salesInformation.exportOther ? false : true} name="exportOther"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											exportOther: false,
											other: ""
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="exportOtherNo">No</label>
				</div>
				<div className="inputContainer">
					<input
						className={!props.informationCompliance.salesInformation.exportOther ? "disabled" : undefined}
						disabled={!props.informationCompliance.salesInformation.exportOther}
						value={props.informationCompliance.salesInformation.other ?? ""}
						type="text" placeholder="Countries"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											other: e.target.value
										}
									}
								}
							})
						}}
					/>
				</div>
				<div className="inputContainer radio">
					<span className="label">Subscribed on the IMMEX Program?</span>
					<input id="immexSubscribedYes" type="radio" checked={props.informationCompliance.salesInformation.immexSubscribed ? true : false} name="immexSubscribed"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											immexSubscribed: true
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="immexSubscribedYes">Yes</label>
					<input id="immexSubscribedNo" className="no" type="radio" checked={props.informationCompliance.salesInformation.immexSubscribed ? false : true} name="immexSubscribed"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											immexSubscribed: false
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="immexSubscribedNo">No</label>
				</div>

				<div className="w-100 mt-5"></div>

				<div className="inputContainer radio moreWidth">
					<span className="label">Has your company been certified by any of your customers? <bg-grey>(Please specify)</bg-grey></span>
					<input id="certifiedCustomersYes" type="radio" checked={props.informationCompliance.salesInformation.certifiedCustomers ? true : false} name="certifiedCustomers"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											certifiedCustomers: true
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="certifiedCustomersYes">Yes</label>
					<input id="certifiedCustomersNo" className="no" type="radio" checked={props.informationCompliance.salesInformation.certifiedCustomers ? false : true} name="certifiedCustomers"
						onChange={(e) => {
							props.setSupplier(prevState => {
								return {
									...prevState,
									informationCompliance: {
										...prevState.informationCompliance,
										salesInformation: {
											...prevState.informationCompliance.salesInformation,
											certifiedCustomers: false
										}
									}
								}
							})
						}}
					/>
					<label htmlFor="certifiedCustomersNo">No</label>
				</div>

				<div className="w-100"></div>

				{props.informationCompliance.salesInformation.certifiedCustomers && props.informationCompliance.salesInformation.customersCertified.map((customer, index) => (
					<React.Fragment key={index}>
						<div className="inputContainer containerCustomersCertified">
							<input type="text" placeholder="Customer" value={customer}
								onChange={(e) => {
									const customersCertified = JSON.parse(JSON.stringify(props.informationCompliance.salesInformation.customersCertified))

									customersCertified[index] = e.target.value

									props.setSupplier(prevState => {
										return {
											...prevState,
											informationCompliance: {
												...prevState.informationCompliance,
												salesInformation: {
													...prevState.informationCompliance.salesInformation,
													customersCertified: customersCertified
												}
											}
										}
									})
								}}
							/>
							<img className="deleteIcon" src={deleteIcon} onClick={() => deleteCustomerCertified(index)} alt="Prodensa Supplier" />

						</div>
					</React.Fragment>
				))}

				<div className="w-100"></div>

				{props.informationCompliance.salesInformation.certifiedCustomers &&
					<button className="btnBgBlueTextGradient add" onClick={() => addCustomersCertified()}>Add Customer</button>}
			</div>
		</section>
	)
}