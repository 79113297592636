import "../../assets/css/tabs/productAndService.css"
import Swal from "sweetalert2"
import Axios from "axios"
import sort from "../../assets/images/sort.svg"
import close from "../../assets/images/close.svg"
import moreDots from "../../assets/images/moreDots.svg"
import { Modal, Dropdown } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { formatCommas } from "../../utilities/formatCommas";
import moment from "moment";
import { COMMODITIES_OPTIONS } from "../../utilities/utils.js"

// MODAL FOR ADD PRODUCTS
function ProductModalAdd(props) {
	// VARIABLE TO MANAGE THE PRODUCT
	const [name, setName] = useState("")
	const [brand, setBrand] = useState("")
	const [partNumber, setPartNumber] = useState("")
	const [manufacturingPart, setManufacturingPart] = useState("")
	const [commodity, setCommodity] = useState("")
	const [dimensions, setDimensions] = useState("")
	const [drawing, setDrawing] = useState("")
	const [picture, setPicture] = useState("")
	// const [quantity, setQuantity] = useState(0)
	const [packaging, setPackaging] = useState(1)
	const [minimumOrderQuantity, setMinimumOrderQuantity] = useState(1)
	const [unitsPerPackage, setUnitsPerPackage] = useState(1)
	const [price, setPrice] = useState(0)
	const [description, setDescription] = useState("")
	const [barcode, setBarcode] = useState("")
	const [updatedAt, setUpdatedAt] = useState(new Date(props.serverDate.month + "/" + props.serverDate.day + "/" + props.serverDate.year))

	// FUNCTION TO HANDLE THE CANCEL ACTION
	const handleCancelModal = () => {
		setName("")
		setBrand("")
		setPartNumber("")
		setManufacturingPart("")
		setCommodity("")
		setDimensions("")
		setDrawing("")
		setPicture("")
		// setQuantity(0)
		setPackaging(0)
		setMinimumOrderQuantity(1)
		setUnitsPerPackage(1)
		setPrice(0)
		setBarcode("")
		setDescription("")
		props.onHide()
	}

	// FUNCTION TO HANDLE THE ADD ACTION
	const handleAddModal = () => {
		const supplierToUpdate = JSON.parse(JSON.stringify(props.supplier))

		const newProduct = {
			name,
			brand,
			partNumber,
			manufacturingPart,
			commodity,
			dimensions,
			drawing: {
				document: drawing,
				updatedAt: null,
				yearCreatedAt: props.serverDate.year,
				monthCreatedAt: props.serverDate.month,
				dayCreatedAt: props.serverDate.day,
			},
			picture: {
				document: picture,
				uploadedAt: null,
				yearCreatedAt: props.serverDate.year,
				monthCreatedAt: props.serverDate.month,
				dayCreatedAt: props.serverDate.day,
			},
			packaging,
			minimumOrderQuantity,
			unitsPerPackage,
			price,
			barcode,
			description,
			updatedAt
		}

		supplierToUpdate.productsAndServices.products.push(newProduct)

		props.handleSaveChanges(supplierToUpdate)

		handleCancelModal()
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			centered
			className="productAndServicesModal"
		>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
				<h4>Add a new product</h4>
				<p className="subTitle">Please provide us with some information about your product</p>
				<div className="inputsSection">
					<div className="inputContainer">
						<span className="label">Barcode</span>
						<input type="text" value={barcode}
							onChange={(e) => setBarcode(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Name</span>
						<input type="text" value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Brand</span>
						<input type="text" value={brand}
							onChange={(e) => setBrand(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Part Number</span>
						<input type="text" value={partNumber}
							onChange={(e) => setPartNumber(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Manufacturing Part</span>
						<input type="text" value={manufacturingPart}
							onChange={(e) => setManufacturingPart(e.target.value)}
						/>
					</div>
					<div className="inputContainer commodity-container">
						<span className="label">Commodity</span>
						<Dropdown>
							<Dropdown.Toggle className={`${commodity ? "" : "default"}`}>
								{commodity ? commodity : "Commodity"}
							</Dropdown.Toggle>

							<Dropdown.Menu style={{ maxWidth: "100%" }}>
								{COMMODITIES_OPTIONS.map((commodity, index) => (
									<Dropdown.Item key={index} onClick={(e) => setCommodity(commodity)}>{commodity}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="inputContainer">
						<span className="label">Dimensions</span>
						<input type="text" value={dimensions}
							onChange={(e) => setDimensions(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Drawing</span>
						<input type="file"
							onChange={(e) => setDrawing(e.target.files[0])}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Product picture</span>
						<input type="file"
							onChange={(e) => setPicture(e.target.files[0])}
						/>
					</div>
					{/* <div className="inputContainer">
						<span className="label">Quantity</span>
						<input type="text" value={formatCommas(quantity).formattedValue}
							onChange={(e) => setQuantity(+formatCommas(e.target.value).numericValue)}
						/>
					</div> */}

					<div className="w-100"></div>

					<div className="inputContainer">
						<span className="label">Bundle packaging</span>
						<input type="text" value={packaging && formatCommas(packaging).formattedValue}
							onChange={(e) => setPackaging(+formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Bundle minimun order quantity</span>
						<input type="text" value={minimumOrderQuantity && formatCommas(minimumOrderQuantity).formattedValue}
							onChange={(e) => setMinimumOrderQuantity(+formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Units per package</span>
						<input type="text" value={unitsPerPackage && formatCommas(unitsPerPackage).formattedValue}
							onChange={(e) => setUnitsPerPackage(+formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Bundle price</span>
						<input type="text" value={formatCommas(price).formattedValue}
							onChange={(e) => setPrice(+formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Description</span>
						<textarea rows={5} value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>
				</div>
				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={() => handleCancelModal()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleAddModal()}>Add</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

// MODAL FOR EDIT PRODUCTS
function ProductModalEdit(props) {
	// ORIGINAL DATA
	const [data, setData] = useState(null)
	const rfc = props.rfc

	useEffect(() => {
		setData(props.data)
	}, [props.data])

	// FUNCTION TO HANDLE THE CANCEL ACTION
	const handleCancelModal = () => {
		props.onHide()
	}

	// FUNCTION TO HANDLE THE ADD ACTION
	const handleEditModal = async () => {
		const supplierToUpdate = JSON.parse(JSON.stringify(props.supplier))

		supplierToUpdate.productsAndServices.products[props.position] = data

		props.setSupplier(supplierToUpdate)

		handleCancelModal()

		try {
			const response = await props.handleSaveChanges(supplierToUpdate)
			if (response && !props.firstTime) {
				Axios.put(`${process.env.REACT_APP_PURCHASING_LOCALHOST}supplier/updateSpecificProduct`, { specificProduct: data, supplierRfc: rfc }).then(res => {
				}).catch(err => console.log(err))
			}
		} catch (error) {
			Swal.fire({
				toast: true,
				icon: 'error',
				text: 'Failed to save information',
				showConfirmButton: false,
				timer: 3000,
				timerProgressBar: true,
				position: "bottom-end"
			})
			console.log(error)
		}
	}

	// FUNCTION TO HANDLE THE PRODUCT CHANGE
	const handleChangeProduct = (property, value) => {
		if (property === "drawing" || property === "picture") {
			setData(prevState => {
				return {
					...prevState,
					[property]: {
						...prevState[property],
						document: value
					}
				}
			})
		} else {
			setData(prevState => {
				return {
					...prevState,
					[property]: value
				}
			})
		}
	}

	// FUNCTION TO HANDLE THE PRODUCT DELETE 
	const handleDeleteModal = () => {
		props.handleDeleteProductAndService("products", props.position)
		handleCancelModal()
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			centered
			className="productAndServicesModal"
		>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
				<h4>Edit product</h4>
				<p className="subTitle">Please provide us with some information about your product</p>
				{data && <div className="inputsSection">
					<div className="inputContainer">
						<span className="label">Barcode</span>
						<input type="text" value={data.barcode ?? ""}
							onChange={(e) => handleChangeProduct("barcode", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Name</span>
						<input type="text" value={data.name}
							onChange={(e) => handleChangeProduct("name", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Brand</span>
						<input type="text" value={data.brand}
							onChange={(e) => handleChangeProduct("brand", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Part Number</span>
						<input type="text" value={data.partNumber}
							onChange={(e) => handleChangeProduct("partNumber", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Manufacturing Part</span>
						<input type="text" value={data.manufacturingPart}
							onChange={(e) => handleChangeProduct("manufacturingPart", e.target.value)}
						/>
					</div>
					<div className="inputContainer commodity-container">
						<span className="label">Commodity</span>
						<Dropdown>
							<Dropdown.Toggle className={`${data.commodity ? "" : "default"}`}>
								{data.commodity ? data.commodity : "Commodity"}
							</Dropdown.Toggle>

							<Dropdown.Menu style={{ maxWidth: "100%" }}>
								{COMMODITIES_OPTIONS.map((commodity, index) => (
									<Dropdown.Item key={index} onClick={() => handleChangeProduct("commodity", commodity)}>{commodity}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="inputContainer">
						<span className="label">Dimensions</span>
						<input type="text" value={data.dimensions}
							onChange={(e) => handleChangeProduct("dimensions", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Drawing</span>
						<div className="fileContainer">
							{data.drawing.document
								?
								<React.Fragment>
									<a className="downloadFile btnBgBlueTextGradient"
										href={process.env.REACT_APP_URI_UPLOADS + rfc + "/" + (data.drawing.document.name ?? data.drawing.document)}
										target="_blank">
										View attached file
									</a>
									<span className="fileName">{(data.drawing.document.name ?? data.drawing.document)}</span>
									<img className="close" src={close} alt="Prodensa Supplier"
										onClick={() => handleChangeProduct("drawing", "")} />
								</React.Fragment>
								: <input type="file"
									onChange={(e) => handleChangeProduct("drawing", e.target.files[0])}
								/>
							}
						</div>
					</div>
					<div className="inputContainer">
						<span className="label">Product picture</span>
						<div className="fileContainer">
							{data.picture.document
								?
								<React.Fragment>
									<a className="downloadFile btnBgBlueTextGradient"
										href={process.env.REACT_APP_URI_UPLOADS + rfc + "/" + (data.picture.document.name ?? data.picture.document)}
										target="_blank">
										View attached file
									</a>
									<span className="fileName">{(data.picture.document.name ?? data.picture.document)}</span>
									<img className="close" src={close} alt="Prodensa Supplier"
										onClick={() => handleChangeProduct("picture", "")} />
								</React.Fragment>
								: <input type="file"
									onChange={(e) => handleChangeProduct("picture", e.target.files[0])}
								/>
							}
						</div>
					</div>
					{/* <div className="inputContainer">
						<span className="label">Quantity</span>
						<input type="text" value={formatCommas(data.quantity).formattedValue}
							onChange={(e) => handleChangeProduct("quantity", +formatCommas(e.target.value).numericValue)}
						/>
					</div> */}

					<div className="w-100"></div>

					<div className="inputContainer">
						<span className="label">Bundle packaging</span>
						<input type="text" value={data.packaging && formatCommas(data.packaging).formattedValue}
							onChange={(e) => handleChangeProduct("packaging", +formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Bundle minimun order quantity</span>
						<input type="text" value={data.minimumOrderQuantity && formatCommas(data.minimumOrderQuantity).formattedValue}
							onChange={(e) => handleChangeProduct("minimumOrderQuantity", +formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Units per package</span>
						<input type="text" value={data.unitsPerPackage && formatCommas(data.unitsPerPackage).formattedValue}
							onChange={(e) => handleChangeProduct("unitsPerPackage", +formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Bundle price</span>
						<input type="text" value={formatCommas(data.price).formattedValue}
							onChange={(e) => handleChangeProduct("price", +formatCommas(e.target.value).numericValue)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Description</span>
						<textarea rows={5} value={data.description}
							onChange={(e) => handleChangeProduct("description", e.target.value)}
						/>
					</div>
				</div>}
				<div className="actions">
					<button className="btnBgWhiteTextBlack d-lg-none" onClick={() => handleDeleteModal()}>Delete</button>
					<button className="btnBgWhiteTextBlack" onClick={() => handleCancelModal()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleEditModal()}>Edit</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

// MODAL FOR ADD SERVICES
function ServiceModalAdd(props) {
	// VARIABLE TO MANAGE THE PRODUCT
	const [name, setName] = useState("")
	const [description, setDescription] = useState("")

	// FUNCTION TO HANDLE THE CANCEL ACTION
	const handleCancelModal = () => {
		setName("")
		setDescription("")
		props.onHide()
	}

	// FUNCTION TO HANDLE THE ADD ACTION
	const handleAddModal = () => {
		props.setSupplier(prevState => {
			return {
				...prevState,
				productsAndServices: {
					...prevState.productsAndServices,
					services: [...prevState.productsAndServices.services, {
						name: name,
						description: description,
					}]
				}
			}
		})
		handleCancelModal()
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			centered
			className="productAndServicesModal"
		>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
				<h4>Add a new service</h4>
				<p className="subTitle">Please provide us with some information about your service</p>
				<div className="inputsSection">
					<div className="inputContainer service">
						<span className="label">Name</span>
						<input type="text" value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Description</span>
						<textarea rows={5} value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>
				</div>
				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={() => handleCancelModal()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleAddModal()}>Add</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

// MODAL FOR EDIT SERVICES
function ServiceModalEdit(props) {
	// ORIGINAL DATA
	const [data, setData] = useState(null)

	useEffect(() => {
		setData(props.data)
	}, [props.data])

	// FUNCTION TO HANDLE THE CANCEL ACTION
	const handleCancelModal = () => {
		props.onHide()
	}

	// FUNCTION TO HANDLE THE ADD ACTION
	const handleEditModal = () => {
		props.setSupplier(prevState => {
			return {
				...prevState,
				productsAndServices: {
					...prevState.productsAndServices,
					services: prevState.productsAndServices.services.map((service, index) => {
						if (index === props.position) {
							return data
						} else {
							return service
						}
					})
				}
			}
		})
		handleCancelModal()
	}

	// FUNCTION TO HANDLE THE PRODUCT CHANGE
	const handleChangeService = (property, value) => {
		setData(prevState => {
			return {
				...prevState,
				[property]: value
			}
		})
	}

	// FUNCTION TO HANDLE THE PRODUCT DELETE 
	const handleDeleteModal = () => {
		props.handleDeleteProductAndService("services", props.position)
		handleCancelModal()
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="lg"
			centered
			className="productAndServicesModal"
		>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />
				<h4>Edit service</h4>
				<p className="subTitle">Please provide us with some information about your service</p>
				{data && <div className="inputsSection">
					<div className="inputContainer service">
						<span className="label">Name</span>
						<input type="text" value={data.name}
							onChange={(e) => handleChangeService("name", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Description</span>
						<textarea rows={5} value={data.description}
							onChange={(e) => handleChangeService("description", e.target.value)}
						/>
					</div>
				</div>}
				<div className="actions">
					<button className="btnBgWhiteTextBlack d-lg-none" onClick={() => handleDeleteModal()}>Delete</button>
					<button className="btnBgWhiteTextBlack" onClick={() => handleCancelModal()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleEditModal()}>Edit</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default function ProductsAndServices(props) {
	const {
		handleSaveChanges = (() => { })
	} = props
	// VARIABLE TO SHOW THE PRODUCTS AND SERVICES
	const productsAndServices = JSON.parse(JSON.stringify(props.productsAndServices))

	// VARIABLE TO SHOW THE MODAL ADD
	const [showModalAddProduct, setShowModalAddProduct] = useState(false)
	const [showModalAddService, setShowModalAddService] = useState(false)

	// VARIABLE TO SHOW THE MODAL EDIT
	const [showModalEditProduct, setShowModalEditProduct] = useState(false)
	const [showModalEditService, setShowModalEditService] = useState(false)

	// VARIABLE TO SAVE THE MODAL DATA
	const [dataModalEditProduct, setDataModalEditProduct] = useState(null)
	const [dataModalEditService, setDataModalEditService] = useState(null)

	// VARIABLE TO KNOW WHICH POSITION TO EDIT
	const [positionModalEdit, setPositionModalEdit] = useState(null)

	// VARIABLE TO MANAGE THE REF
	const moreDotsRef = useRef(null)
	const productsInput = useRef(null)

	useEffect(() => {
		document.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.removeEventListener("click", closeMenus)
		}
	}, [])

	// FUNTION TO HANDLE THE MENU CLOSE
	const closeMenus = (e) => {
		let menuOptions;

		if (moreDotsRef.current && moreDotsRef.current.contains(e.target)) {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				if (!moreDotsRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			})

			moreDotsRef.current.nextElementSibling.classList.toggle("show")
		} else {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				element.classList.remove("show")
			})
		}
	}

	// FUNTION TO HANDLE THE PRODUCTS AND SERVICES DELETE
	const handleDeleteProductAndService = (property, position) => {
		productsAndServices[property].splice(position, 1)

		props.setSupplier(prevState => {
			return {
				...prevState,
				productsAndServices: productsAndServices
			}
		})
	}

	// FUNTION TO HANDLE THE PRODUCTS AND SERVICES OPEN MODAL
	const handleOpenEditModal = (whichOne, data, position) => {
		if (whichOne === "product") {
			setShowModalEditProduct(true)
			setDataModalEditProduct(data)
			setPositionModalEdit(position)
		} else {
			setShowModalEditService(true)
			setDataModalEditService(data)
			setPositionModalEdit(position)
		}
	}

	function downloadProductsTemplate() {
		window.open(process.env.REACT_APP_DOWNLOAD_PRODUCTS_TEMPLATE, "_blank")
	}

	async function uploadProductsFile(e) {
		const selectedFile = e.target.files[0];
		const allowTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

		if (!selectedFile) return

		if (selectedFile && allowTypes !== selectedFile.type) {
			await Swal.fire({
				icon: "error",
				title: "File not supported",
				showConfirmButton: false,
				showCancelButton: false,
				timer: 2500,
				timerProgressBar: true,
			});

			return;
		}

		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const data = new FormData()
		data.append("products", selectedFile)

		Axios.patch(process.env.REACT_APP_URI_SUPPLIER_PRODUCTS + props.supplier._id, data).then(res => {
			productsInput.current.value = ""

			Swal.fire({
				icon: 'success',
				text: `${res.data.success} ${res.data.success > 1 ? "products" : "product"} added, 
				${res.data.errors} ${res.data.errors > 1 ? "products" : "product"} with errors and 
				${res.data.duplicate} duplicated ${res.data.duplicate > 1 ? "products" : "product"} not added`,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})

			if (res.data.supplier) {
				props.setSupplier(res.data.supplier)
			}
		}).catch(err => {
			console.log(err)
			Swal.fire({
				icon: 'error',
				text: `Some error while uploading products`,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			productsInput.current.value = ""
		})
	}

	return (
		<section className="productsAndServices">
			<div className="tableContainer">
				<h5 className="titleHeader">
					Products

					<div className="productsActions">
						<button className="btnBgBlueTextGradient downloadButton" onClick={downloadProductsTemplate}>Download template</button>

						<input type="file" accept="xlsx" ref={productsInput} style={{ display: "none" }} onChange={uploadProductsFile} />

						<button className="btnBgBlueTextGradient uploadButton" onClick={() => productsInput?.current.click()}>Upload products</button>
						{(props.userLogged.rol !== "Admin client" || props.userLogged.rol !== "Client") && <button className="btnBgBlueTextGradient" onClick={() => setShowModalAddProduct(true)}>
							Add product
						</button>}
					</div>
				</h5>
				<table className="tableProductsAndServices">
					<thead>
						<tr>
							<th><span>
								Name
								<img className="sortIcon" src={sort} alt="Cattenna Platform" />
							</span></th>
							<th><span>
								Part number
								<img className="sortIcon" src={sort} alt="Cattenna Platform" />
							</span></th>
							<th><span>
								Date uploaded
								<img className="sortIcon" src={sort} alt="Cattenna Platform" />
							</span></th>
						</tr>
					</thead>
					<tbody>
						{productsAndServices.products.map((product, index) => (
							<tr key={index}>
								<td>
									<div className="infoContainer">
										<span>{product.name}</span>
									</div>
								</td>
								<td>
									<div className="infoContainer">
										<span>{product.partNumber}</span>
									</div>
								</td>
								<td>
									<div className="infoContainer">
										<span>{moment(product.updatedAt).format("DD MMMM YYYY")}</span>
										<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
										<div className="menuOptions">
											<span onClick={() => handleOpenEditModal("product", product, index)}>Edit</span>
											<span onClick={() => handleDeleteProductAndService("products", index)}>Delete</span>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="tableContainer">
				<h5 className="titleHeader">
					Services
					{(props.userLogged.rol !== "Admin client" || props.userLogged.rol !== "Client") && <button className="btnBgBlueTextGradient" onClick={() => setShowModalAddService(true)}>
						Add Service
					</button>}
				</h5>
				<table className="tableProductsAndServices">
					<thead>
						<tr>
							<th><span>
								Name
								<img className="sortIcon" src={sort} alt="Cattenna Platform" />
							</span></th>
							<th><span>
								Description
							</span></th>
						</tr>
					</thead>
					<tbody>
						{productsAndServices.services.map((service, index) => (
							<tr key={index}>
								<td>
									<div className="infoContainer">
										<span>{service.name}</span>
									</div>
								</td>
								<td>
									<div className="infoContainer">
										<span>{service.description}</span>
										<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
										<div className="menuOptions">
											<span onClick={() => handleOpenEditModal("service", service, index)}>Edit</span>
											<span onClick={() => handleDeleteProductAndService("services", index)}>Delete</span>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="tableContainerMobile d-lg-none">
				<h5 className="titleHeader">
					Products
					{(props.userLogged.rol !== "Admin client" || props.userLogged.rol !== "Client") && <button className="btnBgBlueTextGradient" onClick={() => setShowModalAddProduct(true)}>
						Add product
					</button>}
				</h5>

				{productsAndServices.products.map((product, index) => (
					<div className="productsAndServicesInfo" key={index} onClick={() => handleOpenEditModal("product", product, index)}>
						<span className="value">{product.name}</span>
						<span className="title">Name</span>
						<span className="value">{product.partNumber}</span>
						<span className="title">Part Number</span>
						<span className="value">{moment(product.updatedAt).format("DD MMMM YYYY")}</span>
						<span className="title">Date updated</span>
					</div>
				))}
			</div>

			<div className="tableContainerMobile d-lg-none">
				<h5 className="titleHeader">
					Services
					{(props.userLogged.rol !== "Admin client" || props.userLogged.rol !== "Client") && <button className="btnBgBlueTextGradient" onClick={() => setShowModalAddService(true)}>
						Add Service
					</button>}
				</h5>

				{productsAndServices.services.map((service, index) => (
					<div className="productsAndServicesInfo" key={index} onClick={() => handleOpenEditModal("service", service, index)}>
						<span className="value">{service.name}</span>
						<span className="title">Name</span>
						<span className="value">{service.description}</span>
						<span className="title">Description</span>
						<span className="value">{moment(service.updatedAt).format("DD MMMM YYYY")}</span>
						<span className="title">Date updated</span>
					</div>
				))}
			</div>

			<ProductModalAdd
				supplier={props.supplier}
				show={showModalAddProduct}
				onHide={() => setShowModalAddProduct(false)}
				setSupplier={props.setSupplier}
				serverDate={props.serverDate}
				handleSaveChanges={handleSaveChanges}
				firstTime={props.firstTime}
			/>

			<ServiceModalAdd
				show={showModalAddService}
				onHide={() => setShowModalAddService(false)}
				setSupplier={props.setSupplier}
				serverDate={props.serverDate}
			/>

			<ProductModalEdit
				supplier={props.supplier}
				show={showModalEditProduct}
				onHide={() => setShowModalEditProduct(false)}
				setSupplier={props.setSupplier}
				data={dataModalEditProduct}
				position={positionModalEdit}
				handleDeleteProductAndService={handleDeleteProductAndService}
				rfc={props.rfc}
				handleSaveChanges={handleSaveChanges}
				firstTime={props.firstTime}
			/>

			<ServiceModalEdit
				show={showModalEditService}
				onHide={() => setShowModalEditService(false)}
				setSupplier={props.setSupplier}
				data={dataModalEditService}
				position={positionModalEdit}
				handleDeleteProductAndService={handleDeleteProductAndService}
			/>
		</section>
	)
}